import React, { useState } from "react";
import { sendPasswordResetEmail,getAuth } from "firebase/auth";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
const Button = styled.button`
  background-color: blue;
  padding: 10px;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 20px;
  width: 100%;

  &:hover {
    background-color: white;
    color: blue;
    border: 2px solid blue;
  }
`;

function ResetPassword() {
  const [error, setError] = useState("");
  const [errtype, setErrType] = useState("");
    const [email, setEmail] = useState('')
    const auth = getAuth();
  
    const triggerResetEmail = async () => {
      await sendPasswordResetEmail(auth, email);
      setErrType("success");
      setError("Wir haben eine Email an Sie versendet, über den enthaltenen Link können Sie Ihr Passwort.");  
    }
    return (
        <div className="p-4 box" style={{ width: "70%", margin: "100px auto" }}>
      {error && <Alert variant={errtype}>{error}</Alert>}
      <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email Addresse"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required 
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" type="Button" onClick={triggerResetEmail}>
              Password zurücksetzen
            </Button>
          </div>
      </Form>
      </div>
    )
  }
  
  export default ResetPassword;