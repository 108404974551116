import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 12
    },
    reportTitle: {
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 40
    },
    reportkonto: {
        fontSize: 10,
        textAlign: 'left',
    }    
});
const InvoiceThankYouMsg = ({ invoice }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>*** Vielen Dank für Ihre Buchung ***</Text>
        <Text style={styles.reportkonto}>Bitte Überweisen Sie den oben genannten Betrag innerhalb von 14 Tagen an folgendes Konto:</Text>
        <Text style={styles.reportkonto}>Kontoinhaber: {invoice.cname} | IBAN: {invoice.ciban} | BIC: {invoice.cbic}</Text>
    </View>
);

export default InvoiceThankYouMsg;