import React from "react";

function Impressum() {
  return (
    <div className="container aboutus">
            <div className="about_company">
        <div className="pt-4">
          <h1 className="display-4 text-center my-5">Impressum</h1>
          <div className="about_info">
                    <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>Gerhard Stolz<br />
            Dorfstrasse 2<br />
            16818 Wall</p>

            <h2>Kontakt</h2>
            <p>Telefon: <br />
            E-Mail: </p>

            <h2>Redaktionell verantwortlich</h2>
            <p>Betreiber</p>

            <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
          </div>

        </div>
      </div>
    </div>
  );
}
export default Impressum;


