import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { useUserAuth } from "../contexts/UserAuthContext";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { uid } from "uid";

const Button = styled.button`
  background-color: blue;
  padding: 10px;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 20px;
  width: 100%;

  &:hover {
    background-color: white;
    color: blue;
    border: 2px solid blue;
  }
`;

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [errtype, setErrType] = useState("");
  const id = uid();
  const [password, setPassword] = useState("");
  const { signUp } = useUserAuth();
  let navigate = useNavigate();
  var check2 ="";

  async function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setErrType("");
    try {
      check2=await makeid(16);
      if(check2!== null && check2.length===16){
        await signUp(email, password , name , value , id, check2);
        setError("Registrierung erfolgreich, Sie werden automatisch weitergeleitet");
        setErrType("success");
        setTimeout(function(){

          navigate("/");
       }, 2000);
      }
      else{
        setErrType("danger");
        setError("Es ist ein Fehler aufgetreten, bitte probieren Sie es erneut. Sollte dieser Fehler mehrmals auftreten, schreiben Sie uns bitte über das Kontaktformular an.");
      }
    } catch (err) {
      setErrType("danger");
      setError(err.message);
    }
  };

  return (
    <>
      <div className="p-4 box" style={{ width: "70%", margin: "100px auto" }}>
        <h2 className="mb-3">Erstellen Sie Ihren Account</h2>
        {error && <Alert variant={errtype}>{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formbBasicText">
            <Form.Control
              type="text"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email Addresse"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <PhoneInput
            defaultCountry="DE"
            className="phoneInput"
            id="number"
            placeholder="Telefonnummer"
            value={value}
            onChange={setValue}
          />

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Passwort"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Registrieren
            </Button>
          </div>
        </Form>
        <div className="p-4 box mt-3 text-center">
          Sie haben bereits einen Account? <Link to="/signin">Anmelden</Link>
        </div>
      </div>
    </>
  );
};

export default Signup;
