import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { useUserAuth } from "../contexts/UserAuthContext";
import styled from "styled-components";
import {  useNavigate } from "react-router-dom";
const Button = styled.button`
  background-color: blue;
  padding: 10px;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 20px;
  width: 100%;

  &:hover {
    background-color: white;
    color: blue;
    border: 2px solid blue;
  }
`;
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errtype, setErrType] = useState("");
  const { logIn } = useUserAuth();
  const { user } = useUserAuth();
  const navigate = useNavigate();



  const handleSubmit = async (e) => {   
    e.preventDefault();
    setError("");
    setErrType("");
    try {
      await logIn(email, password);  
    } catch (err) {
      setErrType("danger");
      setError(err.message);
    }
  };
  React.useEffect(() => {
    if (user)
    {
      navigate("/");
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  return (
    <>
      <div className="p-4 box" style={{ width: "70%", margin: "100px auto" }}>
        <h2 className="mb-3 text-center">Anmelden</h2>
        {error && <Alert variant={errtype}>{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email Addresse"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Passwort"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              Anmelden
            </Button>
          </div>
        </Form>
        <hr />
        <div className="p-4 box mt-3 text-center">
          Sie haben noch keinen Account? <Link to="/signup">Registrieren</Link>
        </div>
        <div className="p-4 box mt-3 text-center">
          Sie haben Ihr Passwort vergessen? <Link to="/forgot">Zurücksetzen</Link>
        </div>
      </div>
    </>
  );
};

export default Login;
