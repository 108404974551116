import { ref, set, onValue } from "firebase/database";
import React, { useState } from "react";
import { db } from "../firebase";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useUserAuth } from "../contexts/UserAuthContext";
const Contact = () => {
  const navigate = useNavigate();
  const uid = v4();
  const { user } = useUserAuth();
  
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [error, setError] = useState("");
  const [errtype, setErrType] = useState("");
  const [readonly, setReadonly] = useState(false);
  const addContactToFirebase = () => {
    setError("");
    setErrType("");    
    const date = Date.now();
    if (
      date &&
      name &&
      email &&
      phone &&
      message
    ) {
      set(ref(db, `messages/${uid}`), {
          date,  
          name,
          email,
          phone,
          message
      }).then(() => {
        setError("Nachricht gesendet.");
        setErrType("success");
        setname("");
        setemail("");
        setphone("");
        setmessage("");
        setTimeout(function(){
          navigate("/contact-us");
       }, 2000);

      });
    } else {
      setError("Bitte füllen Sie alle erforderlichen Felder aus.");
      setErrType("danger");
      return;
    }
  };
  React.useEffect(() => { 
    if (user)
    {
      onValue(ref(db, "/users/"), (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          // eslint-disable-next-line array-callback-return
          Object.values(data).map((member, index) => {
            if (member.email === user.email) {                                           
                setphone(member.number);
                setname(member.name); 
                setReadonly(true);
            }
          });
        
        };
      }, []);     
      setemail(user.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
<div className="container contact">
    <div className="row">
        <div className="col-md-8 col-12 mx-auto">
            <div className="card shadow-lg border-0 p-4">
                <h1 className="text-center bg-dark text-white display-4 d-inline-block">Kontakt</h1>
                <form>
                    <div className="form-group my-5">
                    {error && <Alert variant={errtype}>{error}</Alert>}
                    <div className="row">
                        
                            <div className="col-md-6 col-12 my-2">
                            <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            id="name"
                            placeholder="Vor- und Nachname*"
                            readOnly={readonly}
                            required                           
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-5">
                        <div className="row">
                            <div className="col-md-6 col-12 mx-auto my-2">
                            <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            id="email"
                            placeholder="Email Adresse*"
                            readOnly={readonly}
                            required
                                />
                            </div>
                            <div className="col-md-6 col-12 mx-auto my-2">
                            <PhoneInput
                              defaultCountry="DE"
                              className="phoneInput"
                              id="phone"
                              placeholder="Telefon-Nr."
                              value={phone}
                              readOnly={readonly}
                              onChange={setphone}
                            />                            
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <textarea
                        className="form-control"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        id="message"
                        row="20" placeholder="Ihre Nachricht*" required
                        ></textarea>
                        </div>
                      <div className="row" style={{padding: "0.5em",margin: "auto", width:"90%"}}>Die mit einem Sternchen (*) gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</div>
                    </div>
                    <div className="form-group form-check"></div>
                    <input type="submit" value="Nachricht abschicken" className="btn btn-block btn-outline-primary" onClick={addContactToFirebase}/>
                </form>
            </div>
        </div>
    </div>
</div>


    
  );
};

export default Contact;
