import React, { useState } from "react";
import { ref, update, onValue } from "firebase/database";
import Loading from "../Components/Loading";
import { db } from "../firebase";
import { useUserAuth } from "../contexts/UserAuthContext";
import { Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth, reauthenticateWithCredential,
  EmailAuthProvider, updateEmail, updatePassword } from "firebase/auth";
const Account = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");  
  const { user } = useUserAuth();
    const [userdata, setUserData] = useState([]);
    const [slug, setSlug] = useState("");
    const [password, setPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [checkpassword, setCheckPassword] = useState("");        
    React.useEffect(() => {
      onValue(ref(db, "/users/"), (snapshot) => {
        setUserData([]);   
        const data = snapshot.val();
        setSlug("");
        if (data !== null) {
          // eslint-disable-next-line array-callback-return
          Object.values(data).map((member, index) => {
            if (member.email === user.email) {                                           
                setUserData((oldArray) => [...oldArray, member]);
                setSlug(Object.keys(data)[index]); 
            }
          });
        
        };
      }, []);   
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.email]);
    
  var ArrayNUser = [];
  var semail="";
  var ArrayNUChanged = [];
  var ArrayEUser = [];
  var ArrayEUChanged = [];

  function reauth(newUserD,ArrayNUser,ArrayEUser) {
    const auth = getAuth();
    const user = auth.currentUser;
    var tmp;
    tmp=false;
    setError("");


    try {
      const credential = EmailAuthProvider.credential(
       semail,
       password
      );

      reauthenticateWithCredential(user, credential).then(() => {

       tmp=true;

      },).catch(()=>{
        setError("Falsches Passwort");
        return false;
        }
        ).finally(()=>{
          if(tmp===true)
          {

         
          var i=0;
         

          if(document.getElementById("e"+newUserD)!= null){
          if ((document.getElementById("e"+newUserD).value.trim().length>0)&&(ArrayEUser[newUserD]!==document.getElementById("e"+newUserD).value)) 
          {
            var newmail = document.getElementById("e"+newUserD).value;
            update(ref(db, `users/${newUserD}`), {
              email:newmail,
            }); 
            const auth = getAuth();
            updateEmail(auth.currentUser, newmail).then(() => {

            }).catch((error) => {
              setError(error);
            });
            i=1;
          }}
          if(document.getElementById("n"+newUserD)!= null){
          if ((document.getElementById("n"+newUserD).value.trim().length>0)&&(ArrayNUser[newUserD]!==document.getElementById("n"+newUserD).value)) 
          {
            update(ref(db, `users/${newUserD}`), {
              number:document.getElementById("n"+newUserD).value,
            });
            i=1;
          }}
          if(i===1)
          {
            setError("Änderungen gespeichert.");
          }
          else
          {
            setError("Es wurde keine Änderung vorgenommen.");
          }

          tmp=false;
          setTimeout(function(){
            navigate("/account");
         }, 1500);
          return true}
          tmp=false;
          setTimeout(function(){
            navigate("/account");
         }, 1500);
        });

     } catch (error) {
      setError("Fehler");
      return false;

     }

     
  }


  const updateuser = (newUserD) => {
  
    document.getElementById("formBasicPassword").value="";

    reauth(newUserD,ArrayNUser,ArrayEUser);


  };

function setUE(id, text)
{
  ArrayEUser[id]=text;
  ArrayEUChanged[id]=true;
}  
function setUN(id, text)
{
 ArrayNUser[id]=text;
 ArrayNUChanged[id]=true;
} 


function reauthPW()
{
  const auth = getAuth();
    const user = auth.currentUser;
    var tmp;
    tmp=false;
    setError("");

    try {
      const credential = EmailAuthProvider.credential(
       semail,
       password
      );
      reauthenticateWithCredential(user, credential).then(() => {

       tmp=true;
      },).catch(()=>{
        setError("Falsches Passwort");
        return false;
        }
        ).finally(()=>{
          if(tmp===true)
          {

         
          var i=0;
    

          if (newpassword===checkpassword) 
          {
            const auth = getAuth();
            updatePassword(auth.currentUser, newpassword).then(() => {

            }).catch((error) => {
              setError(error);

            });
            i=1;
          }
          if(i===1)
          {
            setError("Änderungen gespeichert.");
          }
          else
          {
            setError("Es wurde keine Änderung vorgenommen."); 
          }

          tmp=false;
          setTimeout(function(){

            navigate("/account");
         }, 1500);
          return true}
          tmp=false;
          setTimeout(function(){
            navigate("/account");
         }, 1500);
        });

     } catch (error) {
      setError("Fehler");
      return false;
     }

    

}

const changePW = () => {

  document.getElementById("formBasicPassword1").value="";
  document.getElementById("formBasicPassword2").value="";
  document.getElementById("formBasicPassword3").value="";

  reauthPW();


};

  return (
    <>
      {       
      user ? (     
      userdata ? (
       
        <div className="container contact">
        <div className="row">
            <div className="col-md-8 col-12 mx-auto">
                <div className="card shadow-lg border-0 p-4">
                    <h1 className="text-center bg-dark text-white display-4 d-inline-block">Account</h1>
                    {error && <Alert variant="danger">{error}</Alert>}
                              {/* eslint-disable-next-line*/}
          {userdata.map((item) =>                             
             {
              ArrayEUser[slug]=item.email
              ArrayEUChanged[slug]=false;
              semail=item.email
              ArrayNUser[slug]=item.number
              ArrayNUChanged[slug]=false;}   
              )}
                          {userdata.map((item) =>                             
             ( 
                    <><div className="form-group my-5">
                        <div className="row">
                            <div className="col-md-10 col-12 mx-auto my-2">
                                <Form.Group className="mb-3" name="hname">
                                  <Form.Label>Name</Form.Label>
                                    <Form.Control
                                      type="text" 
                                      placeholder={item.name} 
                                      readOnly                                                          
                                     />
                                </Form.Group>                                         
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-10 col-12 mx-auto my-2">
                            <Form.Group className="mb-3" name="hemail" controlId={"e"+slug} >
                                <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                      type="email"
                                      as="textarea"
                                      placeholder={ArrayEUser[slug]}
                                      onChange={ (e) => setUE(slug,e.target.value)}                                    
                                    />
                            </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 col-12 mx-auto my-2">                            
                            <Form.Group className="mb-3" name="hnummer" controlId={"n"+slug} >
                                <Form.Label>Tel.-Nr.</Form.Label>     
                                <Form.Control
                                type="text"
                                placeholder={ArrayNUser[slug]}
                                onChange={(e) => setUN(slug,e.target.value)}
                                />
                              </Form.Group>                                                 
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-10 col-12 mx-auto my-2">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Passwort</Form.Label>   
                              <Form.Control
                                type="password"
                                placeholder="Passwort"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </Form.Group>                              
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 col-md-10 col-12 mx-auto">
                        <button className="btn btn-outline-dark btn-lg btn-block" onClick={() => updateuser(slug)}>Bestätigen</button>
                    </div>
                    </>
             ))}
                </div>
            </div>
        </div>
        <hr></hr>

        <div className="row">
         
            <div className="col-md-8 col-12 mx-auto">
                <div className="card shadow-lg border-0 p-4">
                <h1 className="text-center bg-dark text-white display-4 d-inline-block">Passwort ändern</h1>
                    <div className="form-group mb-5">
                        <div className="row">
                        <div className="col-md-10 col-12 mx-auto my-2">                               
                                <Form.Group className="mb-3" controlId="formBasicPassword1">
                                <Form.Label>Altes Passwort</Form.Label> 
                                   <Form.Control
                                    type="password"
                                    placeholder="Passwort"
                                    onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </div>                                                      
                        </div>
                        <div className="row">
                        <div className="col-md-10 col-12 mx-auto my-2">
                                <Form.Group className="mb-3" controlId="formBasicPassword2">
                                <Form.Label>Neues Passwort</Form.Label> 
                                    <Form.Control
                                    type="password"
                                    placeholder="Passwort"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-10 col-12 mx-auto my-2">                               
                                <Form.Group className="mb-3" controlId="formBasicPassword3">
                                <Form.Label>Neues Passwort wiederholen</Form.Label> 
                                    <Form.Control
                                    type="password"
                                    placeholder="Passwort"
                                    onChange={(e) => setCheckPassword(e.target.value)}
                                    />
                                </Form.Group>
                            </div>  
                        </div>
                    </div>
                    <div className="mt-5 col-md-10 col-12 mx-auto">
                        <button className="btn btn-outline-dark btn-lg btn-block" onClick={() => changePW()}>Bestätigen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

        ) : (
        <Loading news={"Account loading..."} />
      ) ):(<></>)}       
    </>
  );
};

export default Account;
