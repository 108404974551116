import React, { useState } from "react";
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";
import svg from "../images/about.svg"
// eslint-disable-next-line
{/*
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";*/}
function About() {

  const [news, setNews] = useState([]);
  var info2 = "";
  var info1 = "";
  var titel1 = "";
  var titel2 = "";
  function setV(typ, text)
  {
    if (typ === "Info 2")
    {
      info2 = text;
    }
    else if (typ === "Info 1")
    {
      info1 = text;
    } 
    else if (typ === "Titel 1")
    {
      titel1 = text;
    }
    else if (typ === "Titel 2")
    {
      titel2 = text;
    }              
  }

      const [team, setTeam] = useState([]);
      React.useEffect(() => {
        onValue(ref(db, "/team/"), (snapshot) => {
          setTeam([]);
          const data = snapshot.val();
          if (data !== null) {
            // eslint-disable-next-line array-callback-return
            Object.values(data).map((member) => {
              setTeam((oldArray) => [...oldArray, member]);
            });
          }
        });
        onValue(ref(db, "/texte/"), (snapshot) => {
          setNews([]);
          const data = snapshot.val();
          if (data !== null) {
            // eslint-disable-next-line array-callback-return
            Object.values(data).map((newst) => {
              setNews((oldArray) => [...oldArray, newst]);
            });
          }
        });        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      // eslint-disable-next-line no-lone-blocks
      {news ?   
        ( 
          // eslint-disable-next-line 
        news.map((newst) =>     
        {
          newst.page === "Über Uns" ?
          setV(newst.text, newst.content)
          : <></>
        }   
              )) : (
    <></>
          )}  
  return ( 
    
    <div className="container aboutus">
      <div className="row">
        <div className="col-md-6 col-12 my-auto">
          <img
            src={svg}
            alt="about us"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6 col-12 my-auto">
          <h1 className="display-4 text-center my-5">{titel1} </h1>
          <p className="lead text-justify text-center">
          {info1}
          </p>
        </div>
      </div>      
        <div className="team">
        <h1 className="display-4">{titel2}</h1>
        <p className="lead text-justify text-center">
          {info2}
          </p>        
      </div>   
      <div className="row mb-5">
      {team ? (
<>
          {team.map((member) => (
            <div className="col-md-4 col-12 mx-auto my-2" key={member.id}>
            <div className="card border-0 shadow-lg p-4">
              <img
                src={member.image}
                className="card-img-top"
                alt={member.name}
              />
              <div className="card-body">
                <h5 className="card-title mb-0">{member.name}</h5>
                <div className="card-text text-black-50">
                  {member.role} <p className="float-right">dabei seit: {member.since}</p>
                </div>              
                {/*<h6 className="mt-5">CONNECT</h6>
                <div className="d-flex justify-content-around">
                  <FaFacebookSquare className="connect" />
                  <AiFillInstagram className="connect" />
                  <FaLinkedin className="connect" />
                  <IoLogoYoutube className="connect" />
          </div>*/}
              </div>
            </div>
          </div>                                          
              ))}   
              </>          
      ) : (
        <div className="container roomerror">
          <div className="row my-5">
            <div className="col-md-6 col-12 mx-auto">
              <div className="card shadow-lg border-0 p-4 error">
                <h1 className="text-center display-4">Keine Einträge.</h1>
              </div>
            </div>
          </div>
        </div>
      )} 
      </div>
    </div>
    
  );
}
export default About;
