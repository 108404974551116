import React, { useState } from "react";
import svg from "../images/location_icon.svg"
import { db } from "../firebase";
import { ref, onValue } from "firebase/database";

function Location() {
  const [news, setNews] = useState([]);
  var titel = "";
  var info2 = "";
  var info1 = ""; 
  function setV(typ, text)
  {
    if (typ === "Info 2")
    {
      info2 = text;
    }
    else if (typ === "Info 1")
    {
      info1 = text;
    }      
    else if (typ === "Titel")
    {
      titel = text;
    }  
  }
  React.useEffect(() => {
    onValue(ref(db, "/texte/"), (snapshot) => {
      setNews([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((newst) => {
          setNews((oldArray) => [...oldArray, newst]);
        });
      }
    });    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  
// eslint-disable-next-line
{news ?   
  ( 
    // eslint-disable-next-line 
  news.map((newst) =>     
  {
    newst.page === "Lage" ?
    setV(newst.text, newst.content)
    : <></>
  }   
        )) : (
<></>
    )}   

  return (
    <div className="container aboutus">
      <div className="row">
        <div className="col-md-2 col-12 my-auto" style={{margin: "auto", maxWidth:"100px"}}>
          <img
            src={svg}
            alt="about us"
            className="img-fluid"
          />
        </div>
        <div className="col-md-10 col-12 my-auto">
          <h1 className="display-4 text-center my-5">{titel} </h1>
          <p className="lead text-justify text-center">
          {info1}
          </p>
        </div>
      </div>
      <div className="about_company">
        <div className="pt-4">
          <div className="about_info">
          <p><iframe title ="google_maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13249.272502647907!2d12.972855079937004!3d52.826089166546645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a91a44b9478271%3A0xa058a0c1458d3d2c!2sBauernhof%20Stolz!5e0!3m2!1sde!2sde!4v1665997778431!5m2!1sde!2sde" width="100%" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/></p>                    
          {info2}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Location;
