import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Banner from "../Components/Banner";
import StyledHero from "../Components/StyledHero";
import { useUserAuth } from "../contexts/UserAuthContext";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
const SingleRooms = () => {
  const { slug } = useParams();
  const state = useSelector((state) => state);
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  }
  const login = () => navigate('/signin');
  function getRoom(arg) {
    const idiRooms = state[0].rooms.map((item) => item);
    const roomDatas = idiRooms.filter((roomItem) => roomItem.slug === arg);
    return roomDatas;
    }
    
    React.useEffect(() => {
      if(!user)
      {handleShow();}     
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  const roomData = getRoom(slug);
  const name = roomData[0].name;
  const description = roomData[0].description;
  const capacity = roomData[0].capacity;
  const size = roomData[0].size;
  const price = roomData[0].price;
  const extras = roomData[0].extras;
  const breakfast = roomData[0].breakfast;
  const pets = roomData[0].pets;
  const images = roomData[0].images;
  const [...defaultBcg] = images;

  return (
    <>
      <StyledHero img={defaultBcg[0]}>
        <Banner title={`${name} room`}>
          <Link to="/rooms" className="btn btn-primary">
            Zurück zur Übersicht
          </Link>
        </Banner>
      </StyledHero>

      {roomData ? (
        <>
          <section className="single-room container">
            <div className="row">
              {
              // eslint-disable-next-line array-callback-return
              defaultBcg.map((item, index) => {
                if (item.length>0)
                {
                return (
                  <div className="col-md-4 col-12 mx-auto p-4" key={index}>
                    <div className="card border-0 shadow-lg p-2">
                      <img
                        key={index}
                        src={item}
                        alt={name}
                        className="img-fluid"
                        padding="20px"
                      />
                    </div>
                  </div>
                );}
              })}
            </div>
            <div className="single-room-info">
              <article className="desc">
                <h3>Details</h3>
                <p>{description}</p>
              </article>
              <article className="info">
                <h3>Info</h3>
                <h6>Preis : €{price}</h6>
                <h6>Größe : {size} ²m</h6>
                <h6>
                  Max. Personen :{" "}
                  {capacity > 1 ? `${capacity} Personen` : `${capacity} Person`}
                </h6>
                <h6>{pets ? "Tiere erlaubt" : "Tiere nicht erlaubt"}</h6>
                <h6>{breakfast && "Frühstück inklusive"}</h6>
              </article>
            </div>
          </section>
          <section className="room-extras">
            <h3>Extras</h3>
            <ul className="extras">
              {extras.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <div className="p-4 clearfix">
              <div className="row">
                <div className="col-md-3 col-12 ml-auto">
                  {user ? <Link
                    to={`/booknow/${slug}`}
                    className="btn btn-outline-primary btn-block btn-lg float-right "
                  >
                    Jetzt buchen
                  </Link>:       <Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    >
    <Modal.Header>
      <Modal.Title>Fehler</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    Für den Buchungsvorgang ist ein Account notwendig.
    Melden Sie sich an oder registrieren Sie sich bei uns.
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={login}>
        Zum Login
      </Button>
      <Button variant="secondary" onClick={handleClose}>
        Verstanden
      </Button>
    </Modal.Footer>
    </Modal>}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="container roomerror">
          <div className="row my-5">
            <div className="col-md-6 col-12 mx-auto">
              <div className="card shadow-lg border-0 p-4 error">
                <h1 className="text-center display-4">Das tut uns leid</h1>
                <h3>Es konnte kein passendes Zimmer gefunden werden...</h3>
                <Link to="/rooms" className="btn btn-warning mt-4 ">
                  Zurück zur Übersicht
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRooms;
