import { child, get, ref } from "firebase/database";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import Navbar from "./Components/Navbar";
import { db } from "./firebase";
import Home from "./pages/Home";
import { ReadFromFirebase } from "./Redux/actions";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Location from "./pages/Location";
import Privacy from "./pages/Privacy";
import Impressum from "./pages/Impressum";
import About from "./pages/About";
import Contact from "./Components/Contact";
import Error from "./pages/Error";
import Bookings from "./Components/bookings";
import ProtectedRoute from "./Components/ProtectedRoute";
import { UserAuthContextProvider } from "./contexts/UserAuthContext";
import Rooms from "./pages/Rooms";
import SingleRooms from "./pages/SingleRooms";
import Booknow from "./pages/Booknow";
import Footer from "./Components/Footer";
import Check from "./Components/check";
import Account from "./pages/account";
import ResetPassword from "./Components/Forgot";

function App() {
  const dispatch = useDispatch();

  function readFromDatabase() {
    const dbRef = ref(db);
    get(child(dbRef, "/hotels"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          dispatch(ReadFromFirebase(data));
        } else {
          console.log("no data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    async function start() {
      await readFromDatabase();
    }
    start();
    const handleClick = () => {
      if(document.getElementById("navbarSupportedContent").classList.contains('show'))
      { document.getElementById("navbarSupportedContent").classList.remove("show");
      };
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <UserAuthContextProvider>
          <Navbar />
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/rooms/:slug" element={<SingleRooms />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/bookings"
              element={
                <ProtectedRoute>
                  {" "}
                  <Bookings />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/booknow/:slug"
              element={
                <ProtectedRoute>
                  <Booknow />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  {" "}
                  <Account />{" "}
                </ProtectedRoute>
              }
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/location" element={<Location />} /> 
            <Route path="/check/:checkID" element={<Check />} />      
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/forgot" element={<ResetPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </UserAuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
