import React, { useState } from "react";
import Hero from '../Components/Hero'
import Banner from '../Components/Banner';
import { Link } from 'react-router-dom';
import {FaRegMeh} from 'react-icons/fa';
import { db } from "../firebase";
import { ref, onValue } from "firebase/database";
export default function Error() {

    const [backgrounds, setBackgrounds] = useState([]);
    var bg = "";
    function setB(typ, text)
    {
      if (typ === "Error")
      {
        bg = text;
      }     
    }  
    React.useEffect(() => {
      onValue(ref(db, "/backgrounds/"), (snapshot) => {
        setBackgrounds([]);
        const data = snapshot.val();
        if (data !== null) {
          // eslint-disable-next-line array-callback-return
          Object.values(data).map((newst) => {
            setBackgrounds((oldArray) => [...oldArray, newst]);
          });
        }
      });      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);   
    backgrounds ?   
      ( 
        // eslint-disable-next-line 
      backgrounds.map((background) =>     
      {
        setB(background.type, background.image)
      }   
            )) : (
  <></>
        ) 
    
    return (
        <>
        {/* eslint-disable react/style-prop-object*/}
        <Hero hero="roomsError" style={bg}/>
        <Banner title="ERROR 404 NOT FOUND" subtitle="You are lost !! ITs dark everywhere">
                <FaRegMeh className="lost"></FaRegMeh>
                <Link to="/" className="btn btn-warning">
                      Zurück zur Startseite
                </Link>
        </Banner>
        </>
    )
}
