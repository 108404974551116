import React, { useState } from "react";
  import { NavLink, useNavigate } from "react-router-dom";
  import { FaAlignRight } from "react-icons/fa";
  import { useUserAuth } from "../contexts/UserAuthContext";

  const Navbar = () => {
    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const [toogler, setToogler] = useState("#navbarSupportedContent");
    async function handleLogout() {
      try {
        await logOut();
        navigate("/signin");
      } catch {
        console.log("can't logout");
      }
    }

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

    React.useEffect(() => {
      function handleResize() {
        if(getWindowDimensions().width<=1200)
        {
          setToogler("#navbarSupportedContent");
        }
        else
        {
          setToogler("");
        }
        //setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      handleResize();
    }, []);
    return (
      <>
        <nav className="navbar navbar-expand-xl navbar-dark bg-transparent py-2 fixed-top scrolled">
          <div className="container-fluid ">
            <span
              className="navbar-brand font-weight-bolder"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Bauernhof Stolz
            </span>
            <a
              href="void(0)"
              className="navbar-toggler border-0"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span>
                <FaAlignRight className="nav-icon"/>
              </span>
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"                                     
                    data-toggle="collapse"
                    data-target={toogler}
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    exact="true"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    data-toggle="collapse"
                    data-target={toogler}
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    exact="true"
                    to="/rooms"
                  >
                    Zimmer
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    data-toggle="collapse"
                    data-target={toogler}
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    exact="true"
                    to="/location"
                  >
                    Lage
                  </NavLink>
                </li>                
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    data-toggle="collapse"
                    data-target={toogler}
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    exact="true"
                    to="/about"
                  >
                    Über uns
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    data-toggle="collapse"
                    data-target={toogler}
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    exact="true"
                    to="/contact-us"
                  >
                    Kontakt
                  </NavLink>
                </li>

                {user ? (
                  <>
                    <li>
                      <NavLink
                        className="nav-link"
                        data-toggle="collapse"
                        data-target={toogler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        exact="true"
                        to="/bookings"
                      >
                        Buchungen
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="nav-link"
                        data-toggle="collapse"
                        data-target={toogler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        exact="true"
                        to="/account"
                      >
                        Account
                      </NavLink>
                    </li>                    
                    <li>
                      <NavLink
                        className="nav-link"
                        data-toggle="collapse"
                        data-target={toogler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        exact="true"
                        to="/signup"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={handleLogout}
                        >
                          Abmelden
                        </button>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink
                        className="nav-link"
                        data-toggle="collapse"
                        data-target={toogler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        exact="true"
                        to="/signin"
                      >
                        <button type="button" className="btn btn-outline-success">
                          Anmelden
                        </button>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        className="nav-link"
                        data-toggle="collapse"
                        data-target={toogler}
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        exact="true"
                        to="/signup"
                      >
                        <button type="button" className="btn btn-outline-danger">
                          Registrieren
                        </button>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  };
  export default Navbar;
