import { Splide, SplideTrack, SplideSlide } from '../@splidejs/react-splide';
import React, { useState } from "react";
import { db } from "../firebase";
import { onValue, ref } from "firebase/database";

const Gallery = () => {
   // eslint-disable-next-line 

        // read guestbook
        const [gall, setGall] = useState([]);
        React.useEffect(() => {
          onValue(ref(db, "/gallery/"), (snapshot) => {
            setGall([]);
            const data = snapshot.val();
            if (data !== null) {
              // eslint-disable-next-line array-callback-return
              Object.values(data).map((member) => {
                setGall((oldArray) => [...oldArray, member]);
              });
            }
          });
          var element = document.getElementById('gallery-splide');         
          var event = new MouseEvent('mouseenter', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
          var event2 = new MouseEvent('mouseleave', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });          
          element.dispatchEvent(event);   
          setTimeout(function(){
            element.dispatchEvent(event2);  
         }, 1500);             
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
   // eslint-disable-next-line 

  return (
    <center>
    <Splide id="gallery-splide" hasTrack={ false } aria-label="..."   options={ {
      type: 'slide',
      padding:
      {
        top: 50,
        left: 10,
        right: 10,
        bottom: 20,
      },
      perPage: 5,
      perMove: 1,
      breakpoints: {
        1500: {
          perPage: 4,
         
        },        
        1250: {
          perPage: 3,
         
        },
        1000: {
          perPage: 2,
      
        },
        750: {
          perPage: 1,
    
        },
      },
      rewind : true,
      autoplay : true,
      lazyload : true,
      speed : 5000,
      gap   : '1rem',
    } }>
    <SplideTrack>
    {gall ? (
      <>
          {gall.map((member) => (  
       <SplideSlide key={member.id}>
                  <img src={member.image} height="250" width="auto" alt={member.title} />
       </SplideSlide>    
       ))} </>
      ):(<></>)}  
    </SplideTrack>
    <div className="splide__progress">
      <div className="splide__progress__bar" />
    </div>
  </Splide></center>
  );
};
export default Gallery;