import React from "react";


const Hero = ({children , hero, style}) => {

    return (
        <header className={hero} style={{backgroundImage: `url(${style})`}}>
            {children}
        </header>
    )
}

Hero.defaultProps = {
    hero :"defaulHero"
}

export default Hero
