import React from "react";

export default function Title({ title }) {
  return (
    <div className="section-title">
      <h1>{title}</h1>
      <div />
    </div>
  );
}
