import React, { useState  } from "react";
import { Alert } from "react-bootstrap";
import { ref, update,set } from "firebase/database";
import { Table } from "react-bootstrap";
import { useUserAuth } from "../contexts/UserAuthContext";
import { db } from "../firebase";
import { FaCheckCircle } from "react-icons/fa";
import { onValue } from "firebase/database";
const ErrorStatus = () => {

  const ArrayText = [];
  const ArrayTChanged = [];
  const ArrayStatus = [];
  const ArraySChanged = [];
  const { user } = useUserAuth();
  const [erroruser, setErrorUser] = useState(false);
const [error, setError] = useState("");
const [errtype, setErrType] = useState("");
  const updatenews = (newsNumb, content, status) => {
    setError("");
setErrType(""); 
    if (ArrayTChanged[newsNumb]) {
    update(ref(db, `errormessages/${newsNumb}`), {
      content: content,
    });
    
    setErrType("success");
    setError("Änderung gespeichert.")
  }
  if (ArraySChanged[newsNumb]) {
    update(ref(db, `errormessages/${newsNumb}`), {
      status: status,
    });
    
    setErrType("success");
    setError("Änderung gespeichert.")
  }
    else
    {
      setErrType("danger");
      setError("Es wurde keine Änderung vorgenommen.")
    }
  };
function setText(id, text)
 {
  ArrayText[id]=text;
  ArrayTChanged[id]=true;
}
function setStatus(id, status)
 {
  ArrayStatus[id]=status;
  ArraySChanged[id]=true;
} 
const [gall, setGall] = useState([]);
React.useEffect(() => {
    if(user){
      if(user.uid==="QvJNk9VYUaUcP20XAnfcbyCLTLF2"){setErrorUser(true);}
      else{setErrorUser(false);}
    }
    onValue(ref(db, "/errormessages/"), (snapshot) => {
    setGall([]);
    const data = snapshot.val();
    if (data !== null) {
      // eslint-disable-next-line array-callback-return
      Object.values(data).map((member) => {
        setGall((oldArray) => [...oldArray, member]);
      });
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

var next_id=1;
var status_i="";
var content_i="";

const addToFirebase = () => {
  setError("");
setErrType("");
  if((document.getElementById("status")!==null)&&(document.getElementById("content")!==null)){
  if((document.getElementById("status").value.length>0)&&(document.getElementById("content").value.length>0))
  {
    status_i=document.getElementById("status").value;
    content_i=document.getElementById("content").value; 
  if((status_i.length>0) && (content_i.length>0))
  {
    set(ref(db, `errormessages/${next_id}`), {
      id: next_id,
      status: status_i,  
      content: content_i,
      nr: next_id
    }).then(() => {
      setErrType("success");
      setError("Fehler hinzugefügt.");
      document.getElementById("status").value=""; 
      document.getElementById("content").value="";
      next_id=next_id+1;     
    });
  } else {
    setErrType("danger");
    setError("Bitte füllen Sie alle erforderlichen Felder aus.");      
    return;
  }
}
}
};

  return (
    <>
      {erroruser?
      gall ? (
        <>
              <div className="card shadow-lg border-0 p-4">
              <h1 className="text-center bg-dark text-white display-4 d-inline-block">Service hinzufügen</h1>
              {error && <Alert variant={errtype}>{error}</Alert>}
              <form>
                  <div className="form-group my-5">  
                    <div className="row">
                      
                      <div className="col-md-6 col-12 mx-auto my-2">  
                      <label htmlFor="titel">Status</label>                                
                      <input
                      type="text"
                      className="form-control"
                      id="status"
                      placeholder="Offen/Behoben"
                      required
                          />
                      </div>

              </div>                    
                  <div className="row">
                      
                          <div className="col-md-6 col-12 mx-auto my-2">  
                          <label htmlFor="text">Beschreibung</label>                                
                          <input
                          type="text"
                          className="form-control"
                          id="content"
                          placeholder="Beschreibung"
                          required
                              />
                          </div>

                  </div>                                   
                  <div className="form-group form-check"></div>
                  </div>
              </form>
              <button
                className="btn btn-block btn-outline-primary"
                onClick={addToFirebase}
              >
                hinzufügen
              </button>
          </div>
          <div className="scrollable-div">
        <Table
          striped
          bordered
          hover
          size="sm"
          style={{ marginTop: "80px", width: "80%", margin: "80px auto" }}
        >
          <thead>
            <tr>
              <th>Status</th>
              <th>Inhalt</th>
              <th>Speichern</th>
            </tr>
          </thead>
          <tbody>
          {/* eslint-disable-next-line*/}
          {gall.map((item) =>                             
             {ArrayText[item.id]=item.content;
              ArrayStatus[item.id]=item.status;
              next_id=item.id+1;}   
              )}
            {gall.sort((a, b)=>a.status.localeCompare(b.status)).map((item) =>                             
             (                 
              <tr key={item.id}>
                <td>
                <input
                            type="text"
                            className="form-control"
                            placeholder={ArrayStatus[item.id]}
                            onChange={(e) =>  setStatus(item.id,e.target.value)}
                            id={item.id}
                />                   
                  </td>
                <td>
                <textarea
                            type="text"
                            className="form-control"
                            placeholder={ArrayText[item.id]}
                            onChange={(e) =>  setText(item.id,e.target.value)}
                            id={item.id}
                                />
                  </td>

                      <td style={{ textAlign: "center" }}>
                        <FaCheckCircle
                          color="green"                          
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          onClick={() => updatenews(item.id, ArrayText[item.id], ArrayStatus[item.id])}
                        />
                      </td>                             
              </tr>
            )
            )}
            
          </tbody>
        </Table></div>
        </>
      ) : (
        <></>
      )  
      : gall ? (
        <div className="scrollable-div"> <Table
          striped
          bordered
          hover
          size="sm"
          style={{ marginTop: "80px", width: "80%", margin: "80px auto" }}
        >
          <thead>
            <tr>
              <th>Bereits gemeldete Fehler</th>
            </tr>
          </thead>
          <tbody>
            {gall.map((item) =>                             
             (                 
              <tr key={item.nr}>
                <td>
                  {(item.status==="Behoben") ? <p><b>{item.status}</b> {item.content}</p> : <p>{item.status} {item.content}</p>}                                  
                </td>                            
              </tr>
            )
            )}
            
          </tbody>
        </Table></div>
      ) : <></>
      }   
    </>
  );
};

export default ErrorStatus;
