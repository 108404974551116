import { useParams } from "react-router-dom"
import { onValue, ref, update } from "firebase/database";
import { db } from "../firebase";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const Check = () => {
  const navigate = useNavigate();
  let params = useParams()
  const [users, setUsers] = useState([]);
  const [usersid, setUsersId] = useState([]);
  var cstate = 0;
  React.useEffect(() => {
    onValue(ref(db, "/users/"), (snapshot) => {
      setUsers([]);
      const data = snapshot.val();
      setUsersId(data);
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((user) => {         
          setUsers((oldArray) => [...oldArray, user]);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = (userid) => {
    update(ref(db, `users/${userid}`), {
      isActive:true,
      check:"",
    }).then(() => {
      alert("Erfoglreich bestätigt.");
      navigate("/signin");  
    });
  };

  return(
    <>
    {users ? 
        (
            <>            
        {users.map((user, index) => (
          
             user.check === params.checkID ? (
                cstate=1, 
               updateUser(Object.keys(usersid)[index])                                                               
              ) : (
                <></>    
              )
        ))}     
            </>
        ) : 
        (
            <>
            
            </>
        )       
    }
        ({cstate}===1 ?)
    (
      <div className="p-4 box" style={{ width: "70%", margin: "100px auto" }}>
      <h2 className="mb-3 text-center">Dieser Code ist ungültig oder abgelaufen.</h2>
      </div>
    )
    </>
  )
};
export default Check;