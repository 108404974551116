import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,sendEmailVerification

} from "firebase/auth";
import { auth, db } from "../firebase";
import { child, get, ref, set } from "firebase/database";
import { uid } from "uid";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";



const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const navigate = useNavigate();

  const [showa, setShowa] = useState(false);
  const handleClosea = () => setShowa(false);

  const handleShowa = () => {
    setShowa(true);
    setTimeout(function(){
      handleClosea(); 
      navigate("/");
     }, 3000);
  }
  
  const [showe, setShowe] = useState(false);
  const handleClosee = () => setShowe(false);
  const handleShowe = () => setShowe(true); 

  const [showp, setShowp] = useState(false);
  const handleClosep = () => setShowp(false);
  const handleShowp = () => setShowp(true); 
 
  const [showt, setShowt] = useState(false);
  const handleCloset = () => setShowt(false);
  const handleShowt = () => setShowt(true); 

  const [showv, setShowv] = useState(false);
  const handleClosev = () => setShowv(false);
  const handleShowv = () => setShowv(true); 

  const [showee, setShowee] = useState(false);
  const handleCloseee = () => setShowee(false);
  const handleShowee = () => setShowee(true); 

  const [showb, setShowb] = useState(false);
  const handleCloseb = () => setShowb(false);
  const handleShowb = () => setShowb(true); 

  const handleAccept = () => {
    setShowb(false);
    navigate("/contact-us");
  }

  const [user, setUser] = useState({});
  const uuid = uid();

  function logIn(email, password) {
    get(child(ref(db), "/users")).then((data) => {
      const userAuth = Object.values(data.val()).filter(
        (item) => item.email === email && item.isAdmin === false
      );
      const userAuth2 = Object.values(data.val()).filter(
        (item) => item.email === email && item.isAdmin === false && item.isActive === true
      );      
      if (userAuth[0]) {
        if (userAuth2[0].isBlocked)
        { handleShowb();}
        else
        {
          if(userAuth2[0])
          {
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              if (userCredential.user) {
                if (userCredential.user.emailVerified === false) {               
                  sendEmailVerification(userCredential.user);
                  signOut(auth);
                  handleShowv();
                  
                } else {   
                  handleShowa();
                  return('success');         
                }
              } else {  
                            
              }

            })
            .catch(error=>{
              switch(error.code) {
                case 'auth/wrong-password':
                  handleShowp();      
                  //alert('Wrong Password!')
                      break;  
                case  'auth/too-many-requests':
                  handleShowt();
                  //alert('Zu viele Zugriffsversuche');
                      break;
                default : return;                      
                          
            }})         
          }
          else
          {
            handleShowv();
            //alert("Dieser Account ist noch nicht freigeschaltet. Bitte bestätigen Sie Ihre Email-Adresse");
          }
        }
      }
      else
      {
        handleShowe();
        //alert("Please Sign in with User Account."); //wrong email
      }

    });
  }

  function signUp(email, password, name, number, id, check) {
    return createUserWithEmailAndPassword(auth, email, password).then( (userCredential) => {
      sendEmailVerification(userCredential.user);
      set(ref(db, `users/${uuid}`), {
        email,
        name,
        number,
        id,
        isAdmin: false,
        isActive: true,
        isBlocked: false,
        check,
      });
      return signOut(auth);
    })
    .catch(error=>{
      switch(error.code) {
        case 'auth/email-already-in-use':
            handleShowee();      
              break;
        default : return;
    }}
    );
  }
  function logOut() {
    return signOut(auth);
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, }}
    >
      {children}
    </userAuthContext.Provider>
    <Modal
        show={showa}
        onHide={handleClosea}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>

        </Modal.Header>
        <Modal.Body>
        Login erfolgreich, Sie werden automatisch weitergeleitet.
        </Modal.Body>
      </Modal>    
    <Modal
        show={showe}
        onHide={handleClosee}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Ihre Zugangsdaten sind leider falsch, bitte versuchen Sie es erneut.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosee}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showp}
        onHide={handleClosep}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Ihre Zugangsdaten sind leider falsch, bitte versuchen Sie es erneut.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosep}>
          Schließen
          </Button>
        </Modal.Footer>
      </Modal> 
      <Modal
        show={showv}
        onHide={handleClosev}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Ihre Email-Adresse wurde noch nicht bestätigt, es wurde ein neuer Verifizierungslink gesendet.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosev}>
          Schließen
          </Button>
        </Modal.Footer>
      </Modal>   
      <Modal
        show={showt}
        onHide={handleCloset}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Zu viele Fehlversuche, Ihr Account wurde vorübergehend gesperrt, bitte probieren Sie es später erneut.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloset}>
          Schließen
          </Button>
        </Modal.Footer>
      </Modal>  
      <Modal
        show={showee}
        onHide={handleCloseee}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Diese Email-Adresse wird bereits verwendet.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseee}>
          Schließen
          </Button>
        </Modal.Footer>
      </Modal>  
      <Modal
        show={showb}
        onHide={handleCloseb}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Ihr Account wurde gesperrt, bitte kontaktieren Sie den Betreiber.
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleAccept}>
        Zum Kontakt
      </Button>          
          <Button variant="secondary" onClick={handleCloseb}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>                        
    </>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
