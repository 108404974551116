import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ref, set, onValue } from "firebase/database";
import { db } from "../firebase";
import { uid } from "uid";
import { useSelector } from "react-redux";
import { useUserAuth } from "../contexts/UserAuthContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Alert } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export default function Booknow() {
  const { user } = useUserAuth();
  const state = useSelector((state) => state);
  const { slug } = useParams();
  const [fullNmae, setFullNmae] = useState("");
  const [value, setValue] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [persons, setPersons] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const uuid = uid();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);

  }
  const handleAccept = () => {
    setShow(false);
    update();
  }
  const [show2, setShow2] = useState(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => {
    setShow2(false);

  }
  const handleAccept2 = () => {
    setShow2(false);
    handleShow();
  }  
  const [bookings, setBookings] = useState([]);
  const [billings, setBilling] = useState([]);

  const [error, setError] = useState("");
  const [errtype, setErrType] = useState("");

  var host_addr,host_bank,host_bic,host_iban,host_email,host_name,host_tel ="";
  React.useEffect(() => {
    onValue(ref(db, "/bookings/"), (snapshot) => {
      setBookings([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((booking) => {
          setBookings((oldArray) => [...oldArray, booking]);
        });
      }
    });    
    onValue(ref(db, "/billing/"), (snapshot) => {
      setBilling([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((billing) => {
          setBilling((oldArray) => [...oldArray, billing]);
        });
      }
    });    
    onValue(ref(db, "/users/"), (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((member, index) => {
          if (member.email === user.email) {                                           
              setValue(member.number);
              setFullNmae(member.name);              
          }
        });
      
      };
    }, []);     
    setEndDate(startDate.addDays(1));
    setEmail(user.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var blocked = [];

  function handleName(name) {
    setFullNmae(name.target.value);
  }
  function handlepersons(val) {
    if (val.target.value>capacity)
    {setPersons(capacity);}
    else{setPersons(val.target.value);}
  }
  function handleemail(val) {
    setEmail(val.target.value);
  }
  function handleaddress(val) {
    setAddress(val.target.value);
  }
  function handleChangeStart(date) {
    setStartDate(date);
  }


  function handleChangeEnd(date) {
    setEndDate(date);
  }

  function calculateDaysLeft(startDate, endDate) {
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    if (!moment.isMoment(endDate)) endDate = moment(endDate);
    return endDate.diff(startDate, "days");
  }

  function getRoom(arg) {
    const idiRooms = state[0].rooms.map((item) => item);
    const roomDatas = idiRooms.filter((roomItem) => roomItem.slug === arg);
    return roomDatas;
  }

  const room = getRoom(slug);
  var daysLeft = calculateDaysLeft(startDate, endDate);

  const formattedDate = startDate
    .toLocaleDateString("de-DE", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " ");

  const formattedEndDate = endDate
    .toLocaleDateString("de-DE", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " ");


    function loadDates(){ 
      var array=[]     

      bookings?
       (  
      (bookings.map((booking) =>  
      (    

        booking.type === name ?
          
        booking.status !=="Zurückgewiesen" ?
        array=array.concat(getBDates(new Date(deformat(booking.startDate)), new Date(deformat(booking.endDate))))
            : <></>
       : <></>
      ) 
      
        ) 
     )):
     (console.log('error'))     
      return array;
    }
    
    function checkavdates()
    {

      var start = document.getElementById("startD").value.toString();
      var end = document.getElementById("endD").value.toString();
    

      if(start!==end){
      var tmpary = getBDates(new Date(start), new Date(end));
      var i = 0;
      var j = 0;
      while (tmpary.length > i)
      {
        j=0;
        while (blocked.length > j)
        {

          if(tmpary[i].toString() === blocked[j].toString())
          {
            return true;
          }
          j++;
        }
        i++;
      }}
    
      
    }
   /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
    Date.prototype.addDays = function(days) {
      var dat = new Date(this.valueOf())
      dat.setDate(dat.getDate() + days);
      return dat;
  }
    function getBDates(startDate, stopDate) {
      var dateArray = [];

      var currentDate = startDate;
      while (currentDate < stopDate) { 

          dateArray.push(currentDate);

          currentDate = currentDate.addDays(1);
      }

      return dateArray;
  }
  
  const demonth = ['Jan','Feb','März','Apr','Mai','Juni','Juli','Aug','Sept','Okt','Nov','Dez'];
  
  function deformat(start)
  {
    var strar = start.replace(/. /g,' ');    
    strar=strar.split(' ');
    var strday = strar[0];
    if (strday.toString().length===1){strday='0'+strday.toString();}
    var strmonth = strar[1];
    strmonth = demonth.indexOf(strmonth)+1;
    if (strmonth.toString().length===1){strmonth='0'+strmonth.toString();}
    var stryear=strar[2];
    start=strmonth+'/'+strday+'/'+stryear;
    return (start);
  }    

  if (!room) {
    return (
      <div className="container roomerror">
        <div className="row my-5">
          <div className="col-md-6 col-12 mx-auto">
            <div className="card shadow-lg border-0 p-4 error">
              <h1 className="text-center display-4">Das tut uns leid</h1>
              <h3>Es konnte kein passendes Zimmer gefunden werden...</h3>
              <Link to="/rooms" className="btn btn-warning mt-4 ">
              Zurück zur Übersicht
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const name = room[0].name;
  const capacity = room[0].capacity;
  const size = room[0].size;
  const price = room[0].price;
  const breakfast = room[0].breakfast;
  const pets = room[0].pets;
  const images = room[0].images;
var date="";
var rnday="";
var rnmonth="";
var rnyear="";
var rndate="";
var rncounter=0;
  const [...defaultBcg] = images;

  function sameDay(d1, d2) {
    d1=new Date(d1);
    d2=new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  function writeToDatabase() {
    setError("");
    setErrType("");      
    if (persons > capacity) {
      setErrType("danger");
      setError("Bitte Überprüfen Sie die Zimmergröße.");      
      return;
    }
    if (daysLeft <= 0) {
      setErrType("danger");
      setError("Bitte wählen Sie eine Anreise- und Abreisedatum aus.");        
      return;
    }
    if (checkavdates()) {
      setErrType("danger");
      setError("Das gewünschte Zimmer ist in Ihrem gewählten Zeitraum leider belegt. Bitte wählen Sie einen anderen Zeitraum oder ein anderes Zimmer.");
      return;
    }    
    if (
      fullNmae &&
      address &&
      email &&
      value &&
      persons <= capacity &&
      startDate &&
      endDate
    ) {
         
      if(calculateDaysLeft(startDate, endDate)>=28)
      {
        handleShow2();
      }
      else
      {
        handleShow();
      }

    } else {     
      setErrType("danger");
      setError("Bitte alle Felder ausfüllen.");    
      document.getElementById("address").focus(); 
    }
  }

function update()
{
  billings?
      (  
        // eslint-disable-next-line
     (billings.map((billing) =>  
     {  
       host_addr=billing.addr;
       host_bank=billing.bank;
       host_bic=billing.bic;
       host_iban=billing.iban;
       host_email=billing.email;
       host_name=billing.name;
       host_tel=billing.name;
     }
       ) 
    )):<></>  
    date=Date.now();
    rnday=new Date(date).getDate();
    if (rnday<10){rnday="0"+rnday;}
    rnmonth=new Date(date).getMonth()+1;
    if (rnmonth<10){rnmonth="0"+rnmonth;}
    rnyear=new Date(date).getFullYear();
    rndate=rnyear+""+rnmonth+""+rnday;
    rncounter=1;
   bookings?
   (  
     // eslint-disable-next-line
  (bookings.map((booking) =>  
  {    
   if(sameDay(date, booking.date))
   {rncounter=rncounter+1;}
  } 
    ) 
 )):
 (console.log('error'))
 if(rncounter<10)
 {
   if (rncounter===1)
   {
     rncounter="01";
   }
   else
   {
     rncounter="0"+rncounter;
   }
 }
 else
 {
   rncounter=""+rncounter;
 }   
  set(ref(db, `bookings/${uuid}`), {
        name: fullNmae,
        address: address,
        email: email,
        phone: value,
        persons: persons,
        type: name,
        startDate: formattedDate,
        endDate: formattedEndDate,
        totalPrice: daysLeft * price,
        days: daysLeft,
        capacity,
        status : "Ausstehend",
        id: uuid,
        refID: user.email,
        date: date,
        rn: rndate+"/"+rncounter,
        host_addr:host_addr,
        host_bank:host_bank,
        host_bic:host_bic,
        host_iban:host_iban,
        host_email:host_email,
        host_name:host_name,
        host_tel:host_tel,
      }).then(() => {
        setErrType("success");
        setError("Erfolgreich gebucht.");      
        setTimeout(function(){
          navigate("/bookings");
       }, 2000);
        
      });
      setFullNmae("");
      setAddress("");
      setEmail("");
      setValue(0);
      setPersons(0);
}

  blocked=loadDates();
  if (blocked.length === 0 || blocked==='undefined')
  {
    blocked=loadDates();
  }



  return (
    <>
    <Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    >
    <Modal.Header>
      <Modal.Title>Buchungsdetail prüfen</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <table style={{width:"100%"}}>
      <tr><th>Zimmer Details</th></tr>
      <tr><td>Zimmer</td><td>{name}</td></tr>
      <tr><td>Max. Personen</td><td>{capacity}</td></tr>
      <tr><td>Größe</td><td>{size} m²</td></tr>
      <tr><td>Tiere</td><td>{pets === true ? `erlaubt` : `nicht erlaubt`}</td></tr>
      <tr><td>Frühstück</td><td>{breakfast === true ? `inklusive` : `nicht inklusive`}</td></tr>
      <tr><td><hr></hr></td><td><hr></hr></td></tr>
      <tr><th>Zeitraum</th></tr>
      <tr><td>Anreise</td><td>{formattedDate}</td></tr>
      <tr><td>Abreise</td><td>{formattedEndDate}</td></tr>
      <tr><td><hr></hr></td><td><hr></hr></td></tr>
      <tr><th>Ihre Angaben</th></tr>
      <tr><td>Anzahl Personen</td><td>{persons}</td></tr>
      <tr><td>Name</td><td>{fullNmae}</td></tr>
      <tr><td>Tel.</td><td>{value}</td></tr>
      <tr><td>Adresse</td><td>{address}</td></tr>
      <tr><td>Email</td><td>{email}</td></tr>
      <tr><td><hr></hr></td><td><hr></hr></td></tr>
      <tr><th>Kosten {daysLeft * price} €</th></tr>
    </table>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={handleAccept}>
        Buchung bestätigen
      </Button>
      <Button variant="secondary" onClick={handleClose}>
        Buchung bearbeiten
      </Button>
    </Modal.Footer>
    </Modal>
    <Modal
    show={show2}
    onHide={handleClose2}
    backdrop="static"
    keyboard={false}
    >
    <Modal.Header>
      <Modal.Title>Buchungszeitraum prüfen</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <table style={{width:"100%"}}>
        <tr><td>Sie haben einen Zeitraum von mehr als 4 Wochen gewählt.</td></tr>
        <tr><td>Ist das korrekt?</td></tr>
    </table>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={handleAccept2}>
        Bestätigen
      </Button>
      <Button variant="secondary" onClick={handleClose2}>
        Buchung bearbeiten
      </Button>
    </Modal.Footer>
    </Modal>    
    <div className="container my-5">
      <div className="row">
        <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
          <div>
            <h1 className="display-4">Buchung</h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 my-auto">
              <img
                src={images[0] || defaultBcg}
                className="img-fluid"
                alt="selected room"
              />
            </div>
            <div className="col-md-6 col-12 my-auto">
              <h1>Zimmer Details</h1>
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Zimmer Art</th>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <th>Max. Personen</th>
                    <td>{capacity}</td>
                  </tr>
                  <tr>
                    <th>Größe</th>
                    <td>{size} ²m</td>
                  </tr>
                  <tr>
                    <th>Frühstück</th>
                    <td>{breakfast === true ? `inklusive` : `nicht inklusive`}</td>
                  </tr>
                  <tr>
                    <th>Tiere</th>
                    <td>{pets === true ? `erlaubt` : `nicht erlaubt`}</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label htmlFor="Fromdate" className="font-weight-bolder mr-3">
                  Von{" "}
                </label>
                <DatePicker
                  selected={startDate}
                  id="startD"
                  onChange={handleChangeStart}
                  minDate={moment().toDate()}
                  excludeDates={blocked}
                  className="form-control"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label htmlFor="Todate" className="font-weight-bolder mr-3">
                  Bis{" "}
                </label>
                <DatePicker
                  selected={endDate}
                  id="endD"
                  minDate={moment().toDate().addDays(1, 'days')}
                  onChange={handleChangeEnd}
                  excludeDates={blocked}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <h6 className="font-weight-bolder">
               Anzahl der Tage : {daysLeft}
              </h6>
              <mark>Bitte vergewissern Sie sich, dass die Checkin-Zeit zwischen 9 und 12 Uhr liegt.</mark>
            </div>
            <div className="col-md-6 col-12">
              <h6 className="font-weight-bold">
               Kosten pro Tag :{" "}
                <span className="badge badge-info">€ {price}</span>
              </h6>
              <h6 className="font-weight-bold">
                zu zahlende Gesamtkosten:{" "}
                <span className="text-primary">€ {daysLeft * price}</span>
              </h6>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-12 col-12 my-auto">
              <div className="col-md-12 col-12 float-right">
                <form id="bknfrm">
                  <div className="form-group" key="it">
                    <label htmlFor="persons">Anzahl Personen</label>
                    <input
                      type="number"
                      min="1"
                      max={capacity}
                      value={persons}
                      className="form-control"
                      onChange={handlepersons}
                      id="persons"
                      placeholder="Anzahl Personen*"
                      required
                    />
                    <label htmlFor="forName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fullNmae}
                      id="forName"
                      onChange={handleName}
                      placeholder="Vollständiger Name*"
                      readOnly
                      required
                    />
                    <label htmlFor="Number">Nummer</label>
                    <PhoneInput
                      defaultCountry="DE"
                      className="phoneInput"
                      id="number"
                      placeholder="Tragen Sie Ihre Telefonnummer ein"
                      value={value}
                      onChange={setValue}    
                      readOnly                  
                    />


                    <label htmlFor="address">Addresse</label>
                    <input
                      type="text"
                      className="form-control"
                      value={address}
                      onChange={handleaddress}
                      id="address"
                      placeholder="Ihre Adresse*"
                      required
                    />

                    <label htmlFor="exampleInputEmail1">Email Addresse</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={handleemail}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email Adresse*"
                      readOnly
                      required
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Wir geben Ihre Email Adresse an niemanden weiter.
                    </small>
                  </div>
                  <div className="row" style={{padding: "0.5em",margin: "auto", width:"90%"}}>Die mit einem Sternchen (*) gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.</div>
                  <div className="form-group form-check"></div>
                  {error && <Alert variant={errtype}>{error}</Alert>}             
                </form> 
                <button
                  className="btn btn-block btn-outline-primary"

                  onClick={writeToDatabase}
                >
                  Buchung eintragen
                </button>                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
