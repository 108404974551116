import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    header1Container: {
        flex:'1',
    },
    billFrom: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },  
    header2Container: {
        justifyContent:'space-evenly',
        textAlign:'right',
        marginVertical:10,
    },
    no: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },    
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={[{flexDirection:'row', alignItems:'baseline'}]}>
            <View style={styles.header1Container}>
                <Text style={styles.billFrom}>Von:</Text>
                <Text>{invoice.cname}</Text>
                <Text>{invoice.caddr}</Text>
                <Text>{invoice.ctel}</Text>
                <Text>{invoice.cemail}</Text>
            </View>
            <View style={styles.header2Container}>
                <Text>Rechnung Nr: {(invoice.invoice_no).replaceAll(".","")}</Text>
                <Text>Datum: {invoice.trans_date}</Text>
            </View>            
        </View>
    </Fragment>
);

export default InvoiceNo;