import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { onValue, ref, set } from "firebase/database";
import { db } from "../firebase";
import { uid } from "uid";
import "react-phone-number-input/style.css";
import ReactStars from "../react-stars/src/react-stars";
import { Splide, SplideTrack, SplideSlide } from '../@splidejs/react-splide';
import { Alert } from "react-bootstrap";
const Guestbook = () => {
    const [fullNmae, setFullNmae] = useState("");
    const [rating, setRating] = useState(0);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const uuid = uid();
    const [error, setError] = useState("");
    const [errtype, setErrType] = useState("");
    const navigate = useNavigate();
    function handleName(name) {
        setFullNmae(name.target.value);
      } 
    function handleemail(val) {
      setEmail(val.target.value);
    }
    function ratingChanged(rating) {
      setRating(rating);
    }    
    function writeToDatabase() {
      setError("");
      setErrType("");
      if (
        fullNmae &&
        email &&
        message &&
        rating
      ) {
        set(ref(db, `guestbook/${uuid}`), {
          name: fullNmae,
          date: Date.now(),
          email: email,
          message: message,
          rating: rating,
          status : "Ausstehend",
          id: uuid,
        }).then(() => {
          setError("Erfolgreich eingetragen.");
          setErrType("success");
          setTimeout(function(){
            navigate("/");
         }, 2000);
        });
        setFullNmae("");
        setEmail("");
        setMessage("");
        setRating(0);
        } else {
          setError("Bitte alle Felder ausfüllen.");
          setErrType("danger");
      }
    }

    const [bookings, setBookings] = useState([]);
    React.useEffect(() => {
      onValue(ref(db, "/guestbook/"), (snapshot) => {
        setBookings([]);
        const data = snapshot.val();
        if (data !== null) {
          // eslint-disable-next-line array-callback-return
          Object.values(data).map((booking) => {
            setBookings((oldArray) => [...oldArray, booking]);
          });
        }
      });
      var element = document.getElementById('guest-splide');         
      var event = new MouseEvent('mouseenter', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      var event2 = new MouseEvent('mouseleave', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });          
      element.dispatchEvent(event);   
      setTimeout(function(){
        element.dispatchEvent(event2);  
     }, 1500);        
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <div className="container my-5">
        <div className="row">
          <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
            <div>
              <h1 className="display-4">Gästebuch</h1>
            </div>            
            <div className="row my-4">            
              <div className="col-md-12 col-12 my-auto">
              {error && <Alert variant={errtype}>{error}</Alert>}
                <div className="col-md-12 col-12 float-right">
                  <form>
                    <div className="form-group">
                      <label htmlFor="forName">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={fullNmae}
                        id="forName"
                        onChange={handleName}
                        placeholder="Ihr Name"
                        required
                      />  
                      <label htmlFor="exampleInputEmail1">Email Addresse</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={handleemail}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email Adresse"
                        required
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        Wir geben Ihre Email Adresse an niemanden weiter.
                      </small>
                      <label htmlFor="Rating">Wie bewerten Sie uns?</label>
                    <ReactStars
                      id="Rating"
                      count={5}
                      onChange={ratingChanged}
                      size={48}
                      color2={'#ffd700'}
                      value={rating} />
                    </div>
                 
                    <div className="row">
                        <div className="col-12">
                        <textarea
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        id="message"
                        row="20" placeholder="Ihre Nachricht" required
                        ></textarea>
                        </div>
                    </div>
                    <div className="form-group form-check"></div>
                  </form>
                  <button
                    className="btn btn-block btn-outline-primary"
                    onClick={writeToDatabase}
                  >
                    Eintragen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {bookings ? (
            <div className="container my-5">
            <div className="col-md-10 mx-auto col-12 card shadow-lg border-0 p-4">
              <Splide id="guest-splide" hasTrack={ false } aria-label="..."   options={ {
                  type: 'slide',
                  padding:
                  {
                    top: 50,
                    left: '25%',
                    right: 10,
                    bottom: 20,
                  },
                  perPage: 1,
                  perMove: 1,
                  rewind : true,
                  autoplay : true,
                  lazyload : true,
                  speed : 5000,
                  gap   : '1rem',
                } }>
                <SplideTrack>
{bookings.map((booking) => (
              booking.status === "Akzeptiert" ? (
                <SplideSlide key={booking.id}>
                  <div><h5>{booking.name}</h5>{new Intl.DateTimeFormat('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' , hour: '2-digit', minute: '2-digit'}).format(booking.date)}<br/>                  
                  <ReactStars
                      id="Rating"
                      count={5}
                      size={24}
                      edit={false}
                      color2={'#ffd700'}
                      value={booking.rating} />
                    
                  <p style={{maxWidth: '80%',}}>{booking.message}</p></div>
     </SplideSlide>                              
              ) : (<></>)
              ))}
      </SplideTrack>
    <div className="splide__progress">
      <div className="splide__progress__bar" />
    </div>
  </Splide></div>
</div>              
      ) : (
        <div className="container roomerror">
          <div className="row my-5">
            <div className="col-md-6 col-12 mx-auto">
              <div className="card shadow-lg border-0 p-4 error">
                <h1 className="text-center display-4">Keine Einträge.</h1>
              </div>
            </div>
          </div>
        </div>
      )}       
      </div>   
    );
    



    
};

export default Guestbook;
