import React, { useState } from "react";
import { FaHorse, FaHiking } from "react-icons/fa";
import { GiGolfTee, GiHummingbird, GiCanoe, GiFishingPole } from "react-icons/gi";
import { MdDirectionsBike } from "react-icons/md";
import Title from './Title';
import { db } from "../firebase";
import { onValue, ref } from "firebase/database"

const Services = () => {
   const activities = [
      {
        icon: <GiGolfTee />,
        title: "Golf",
        info: "",
        value: 0,     
      },
      {
        icon: <GiHummingbird />,
        title: "Vogelbeobachtung",
        info: "",
        value: 1,
      },
      {
        icon: <GiCanoe />,
        title: "Kanu- Bootsverleih",
        info: "",
        value: 2,
      },
      {
        icon: <MdDirectionsBike />,
        title: "Fahrradtouren",
        info: "",
        value: 3,
      },
      {
        icon: <FaHorse />,
        title: "Reiten",
        info: "",
        value: 4,
      },        
      {
        icon: <FaHiking />,
        title: "Wanderungen",
        info: "",
        value: 5,
      },      
      {
        icon: <GiFishingPole />,
        title: "Angeln",
        info: "",
        value: 6,
      }, 
    ];
    const [acts, setActs] = useState([]);
    React.useEffect(() => {
      onValue(ref(db, "/services/"), (snapshot) => {
        setActs([]);
        const data = snapshot.val();
        if (data !== null) {
          // eslint-disable-next-line array-callback-return
          Object.values(data).map((act) => {
            setActs((oldArray) => [...oldArray, act]);
          });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <div className="container-fluid services">
        <Title title="Aktivitäten in der Region" />
        <div className="row">
        {acts ? (
      <>
          {acts.map((act) => (  
              <div
              className="col-md-4 col-lg-3 col-12 mx-auto my-3"
              key={act.id}
            >
              <div className="card shadow-lg border-0 p-4">
                <article className="service">
                  <span>{activities[act.icon].icon}</span>
                    <h6>{act.titel}</h6>
                    <p>{act.text}</p>
                </article>
              </div>
            </div> 
       ))} </>
      ):(<></>)} 
        </div>        
      </div>
    );
}

export default Services
