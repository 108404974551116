import React, { useState } from "react";
import Hero from "../Components/Hero";
import Banner from "../Components/Banner";
import { Link } from "react-router-dom";
import Services from "../Components/Services";
import Gallery from "../Components/Gallery";
import Guestbook from "../Components/guestbook";
import FeaturedRooms from "../Components/FeaturedRooms";
import { db } from "../firebase";
import { ref, onValue } from "firebase/database";
import ErrorStatus from "../Components/errorstatus";
const Home = () => {

  const [news, setNews] = useState([]);
  const [backgrounds, setBackgrounds] = useState([]);
  var info2 = "";
  var info1 = "";
  var bg = "";
  function setV(typ, text)
  {
    if (typ === "Info 2")
    {
      info2 = text;
    }
    else if (typ === "Info 1")
    {
      info1 = text;
    }       
  }
  function setB(typ, text)
  {
    if (typ === "Main")
    {
      bg = text;
    }
     
  }  
  React.useEffect(() => {
    onValue(ref(db, "/texte/"), (snapshot) => {
      setNews([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((newst) => {
          setNews((oldArray) => [...oldArray, newst]);
        });
      }
    });
    onValue(ref(db, "/backgrounds/"), (snapshot) => {
      setBackgrounds([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((newst) => {
          setBackgrounds((oldArray) => [...oldArray, newst]);
        });
      }
    });      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
// eslint-disable-next-line
  {news ?   
    ( 
      // eslint-disable-next-line 
    news.map((newst) =>     
    {
      newst.page === "Home" ?
      setV(newst.text, newst.content)
      : <></>
    }   
          )) : (
<></>
      )}   
backgrounds ?   
        ( 
          // eslint-disable-next-line 
        backgrounds.map((background) =>     
        {
          setB(background.type, background.image)
        }   
              )) : (
    <></>
          )            
  return (
    <div>      
      {/* eslint-disable react/style-prop-object*/}
      <Hero hero="defaultHero" style={bg}></Hero>
      <Banner title={info1} subtitle={info2}>
        <Link to="/rooms" className="btn btn-primary">
          Unsere Zimmer
        </Link>
          </Banner>
          <Services />
          <Gallery />
          <FeaturedRooms />
          <Guestbook />
          <ErrorStatus />
    </div>
  );
};

export default Home;
