import { onValue, ref, update } from "firebase/database";
import React, { useState } from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import '../superresponsive.css'
import { db } from "../firebase";
import { Link } from "react-router-dom";
import { useUserAuth } from "../contexts/UserAuthContext";
import styled from "styled-components";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from '../Components/generateInvoice/Invoice';
import { MdCancel } from "react-icons/md";
import moment from "moment";
const fileName = "Rechnung";

const StatusTD = styled.td`
  font-weight: bold;
  color: ${(props) => (props.type === "Ausstehend" ? "blue" : "")};
  color: ${(props) => (props.type === "Akzeptiert" ? "green" : "")};
  color: ${(props) => (props.type === "Zurückgewiesen" ? "red" : "")};
  color: ${(props) => (props.type === "Storniert" ? "orange" : "")};
`;
const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [storno, setStorno] = useState();
  const { user } = useUserAuth();

  const cancelBooking = (bookingNumb, status) => {
    update(ref(db, `bookings/${bookingNumb}`), {
      status,
    });
  };

  function deformat(start)
  {
    var strar = start.split('.');
    var strday = strar[0];
    if (strday.toString().length===1){strday='0'+strday.toString();}
    var strmonth = strar[1];
    if (strmonth.toString().length===1){strmonth='0'+strmonth.toString();}
    var stryear=strar[2];
    start=strmonth+'/'+strday+'/'+stryear;
    return (start);
  }  

  function cancelallowed(startDate) {
    if (!moment.isMoment(startDate)) startDate = moment(startDate);
    var today=moment().format("MM/DD/YYYY");
    if (startDate.diff(today, 'days')>=storno)
    {
      return true;
    } 
    else { return false;}
  }
  React.useEffect(() => {
    onValue(ref(db, "/bookings/"), (snapshot) => {
      setBookings([]);
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((todo) => {
          if (todo.refID === user.email) {
            setBookings((oldArray) => [...oldArray, todo]);
          }
        });
      }
    });
    onValue(ref(db, "/storno/"), (snapshot) => {
      setStorno();
      const data = snapshot.val();
      if (data !== null) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map((todo) => {
            setStorno(todo);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  bookings.sort((a,b)=>(a.date > b.date) ? 1 : -1 ) ;

  function renderSwitch(param) {
    switch(param) {
      case 'Zurückgewiesen':
        return ' ';
        case 'Ausstehend':
          return 'nicht mehr stornierbar';  
          case 'Akzeptiert':
            return 'nicht mehr stornierbar';                
            case 'Storniert':
              return ' '; 
      default:
        return ' ';
    }
  } 
  return (
    <>
      {bookings.length > 0 ? (      
        <Table style={{marginTop: "100px"}}>
          <Thead>
            <Tr>
              <Th>Buchung-Nr</Th>
              <Th>Gebucht am</Th>
              <Th>Zimmer</Th>
              <Th>Anreise</Th>
              <Th>Abreise</Th>
              <Th>Personen</Th>
              <Th>Preis</Th>
              <Th>Rechnung</Th>
              <Th>Status</Th>
              <Th>Storno (7 Tage)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bookings.map((booking) => (
              <Tr key={booking.date}>               
                  <Td>{booking.rn}</Td>
                  <Td>{(new Date(booking.date).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }))}</Td>
                  <Td>{booking.type.toUpperCase()}</Td>
                  <Td>{booking.startDate}</Td>
                  <Td>{booking.endDate}</Td>
                  <Td>{booking.capacity}</Td>
                  <Td>{booking.totalPrice}</Td>                  
                  <Td className='download-link'>
                  <PDFDownloadLink
                    document={<PdfDocument invoicedata={ 
                      {   
                        id: "5df3180a09ea16dc4b95f910",
                        invoice_no: "RN/"+booking.rn,
                        balance: "",
                        fullname: booking.name,
                        email: booking.email,
                        phone: booking.phone,
                        address: booking.address,
                        trans_date: (new Date(booking.date).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                        due_date: (new Date(booking.date).toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                        companyID: "",
                        companyName: "",                     
                        cname: booking.host_name,
                        caddr: booking.host_addr,
                        cbank: booking.host_bank,
                        ciban: booking.host_iban,
                        cbic: booking.host_bic,
                        cemail: booking.host_email,
                        ctel: booking.host_tel,
                        items: [
                            {
                                sno: 1,
                                desc: booking.type,
                                range: booking.startDate+" - "+booking.endDate,
                                qty: booking.days,
                                rate: booking.totalPrice/booking.days,
                            }
                        ]
                      }
                    } />}
                    fileName={fileName+" - "+booking.id+".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading..." : "Rechnung"
                    }
                  </PDFDownloadLink>
                </Td>
                <Td><StatusTD type={booking.status}>{booking.status}</StatusTD></Td>               
                <Td style={{ textAlign: "center" }}>
                {cancelallowed(deformat(booking.startDate)) ? (
                
                (booking.status !== "Storniert") && (booking.status !== "Zurückgewiesen") ? (                  
                        <MdCancel
                          color="orange"
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          onClick={() => cancelBooking(booking.id, "Storniert")}
                        />
                  ) : (
                    " "
                  ) ) : renderSwitch(booking.status)
                
                }      
                </Td>                                                 
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <div className="container roomerror">
          <div className="row my-5">
            <div className="col-md-6 col-12 mx-auto">
              <div className="card shadow-lg border-0 p-4 error">
                <h1 className="text-center display-4">Keine Buchungen.</h1>
                <h3>Klicken Sie unten, um zu buchen.</h3>
                <Link to="/rooms" className="btn btn-warning mt-4 ">
                  Buchung starten.
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Bookings;
