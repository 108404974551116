import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"
              
const firebaseConfig = {

  apiKey: "AIzaSyCq_kdazcfJ38CDhe4W3rhOTeoB3J_meU0",

  authDomain: "bh-stolz---dev.firebaseapp.com",

  databaseURL: "https://bh-stolz---dev-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "bh-stolz---dev",

  storageBucket: "bh-stolz---dev.appspot.com",

  messagingSenderId: "45953685972",

  appId: "1:45953685972:web:8a4b2bb18af535c99bde41"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);
export const db = getDatabase();
export default app;
